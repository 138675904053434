import React from "react";
import { Link } from "react-router-dom";
const SideNavBar = () => {
  return (
    <div id="layoutSidenav_nav">
      <nav
        className="sb-sidenav accordion sb-sidenav-dark"
        id="sidenavAccordion"
      >
        <div className="sb-sidenav-menu">
          <div className="nav">
            <Link
              className="nav-link collapsed"
              to="#"
              data-toggle="collapse"
              data-target="#collapseJobCard"
              aria-expanded="false"
              aria-controls="collapseJobCard"
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-credit-card" />
              </div>
              Packages
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </Link>
            <div
              className="collapse"
              id="collapseJobCard"
              aria-labelledby="headingTwo"
              data-parent="#sidenavAccordion"
            >
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/package/all">
                  All Packages
                </Link>
              </nav>
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/package/create">
                  Add Package
                </Link>
              </nav>
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/package/add-featured">
                  Add Featured
                </Link>
              </nav>
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/package/featured">
                  All Featured
                </Link>
              </nav>
             
            </div>

            
            <Link
              className="nav-link collapsed"
              to="#"
              data-toggle="collapse"
              data-target="#collapseHotel"
              aria-expanded="false"
              aria-controls="collapseHotel"
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-users" />
              </div>
             Hotels
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </Link>
            <div
              className="collapse"
              id="collapseHotel"
              aria-labelledby="headingTwo"
              data-parent="#sidenavAccordion"
            >
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/hotel/all">
                  All Hotels
                </Link>
               
              </nav>
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/hotel/add-featured">
                  Add Featured
                </Link>
              </nav>
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/hotel/featured">
                  All Featured
                </Link>
              </nav>
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/hotel/create">
                  Add Hotel
                </Link>
               
              </nav>
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/hotel-category/all">
                  All Categories
                </Link>
               
              </nav>
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/hotel-category/create">
                  Add Categories
                </Link>
               
              </nav>
            </div>
            <Link
              className="nav-link collapsed"
              to="#"
              data-toggle="collapse"
              data-target="#collapseEnquiry"
              aria-expanded="false"
              aria-controls="collapseEnquiry"
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-users" />
              </div>
             Enquiry
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </Link>
            <div
              className="collapse"
              id="collapseEnquiry"
              aria-labelledby="headingTwo"
              data-parent="#sidenavAccordion"
            >
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/enquiry/all">
                  All Enquiry
                </Link>
               
              </nav>
             
            </div>
            <Link
              className="nav-link collapsed"
              to="#"
              data-toggle="collapse"
              data-target="#collapseBlog"
              aria-expanded="false"
              aria-controls="collapseBlog"
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-users" />
              </div>
             Blogs
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </Link>
            <div
              className="collapse"
              id="collapseBlog"
              aria-labelledby="headingTwo"
              data-parent="#sidenavAccordion"
            >
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/blog/all">
                  All Blogs
                </Link>
               
              </nav>
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/blog/create">
                  Add Blog
                </Link>
               
              </nav>
            </div>
            <Link
              className="nav-link collapsed"
              to="#"
              data-toggle="collapse"
              data-target="#collapseCategories"
              aria-expanded="false"
              aria-controls="collapseCategories"
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-users" />
              </div>
              Customer's
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </Link>
            <div
              className="collapse"
              id="collapseCategories"
              aria-labelledby="headingTwo"
              data-parent="#sidenavAccordion"
            >
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/users/list">
                  All Customers
                </Link>
               
              </nav>
            </div>

            <Link
              className="nav-link collapsed"
              to="#"
              data-toggle="collapse"
              data-target="#collapseOrders"
              aria-expanded="false"
              aria-controls="collapseOrders"
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-credit-card" />
              </div>
             Variations
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </Link>
            <div
              className="collapse"
              id="collapseOrders"
              aria-labelledby="headingTwo"
              data-parent="#sidenavAccordion"
            >
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/tag/list">
                  All Tags
                </Link>
              </nav>
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/tag/create">
                  Add Tag
                </Link>
              </nav>
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/category/all">
                  All  categories
                </Link>
              </nav>
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/subcategory/all">
                  All Sub categories
                </Link>
              </nav>
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/specialcategory/all">
                  All Special categories
                </Link>
              </nav>
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/category/create">
                  Add Category
                </Link>
              </nav>
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/subcategory/create">
                  Add Sub Category
                </Link>
              </nav>
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/specialcategory/create">
                  Add Special Category
                </Link>
              </nav>
            </div>

            <Link
              className="nav-link collapsed"
              to="#"
              data-toggle="collapse"
              data-target="#collapseVarList"
              aria-expanded="false"
              aria-controls="collapseVarList"
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-users" />
              </div>
              Booking
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </Link>
            <div
              className="collapse"
              id="collapseVarList"
              aria-labelledby="headingTwo"
              data-parent="#sidenavAccordion"
            >
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/booking/list">
                  All Bookings
                </Link>
                <Link className="nav-link sub_nav_link" to="/booking/vendor">
                 Vendor wise Booking
                </Link>
              </nav>
            </div>
            <Link
              className="nav-link collapsed"
              to="#"
              data-toggle="collapse"
              data-target="#collapsebanList"
              aria-expanded="false"
              aria-controls="collapseVarList"
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-users" />
              </div>
              Banners
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </Link>
            <div
              className="collapse"
              id="collapsebanList"
              aria-labelledby="headingTwo"
              data-parent="#sidenavAccordion"
            >
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/banner/create">
                  Create Banner
                </Link>
                <Link className="nav-link sub_nav_link" to="/banner/list">
                 List Banner
                </Link>
              </nav>
            </div>

            <Link
              className="nav-link collapsed"
              to="#"
              data-toggle="collapse"
              data-target="#collapserevList"
              aria-expanded="false"
              aria-controls="collapseVarList"
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-users" />
              </div>
              Reviews
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </Link>
            <div
              className="collapse"
              id="collapserevList"
              aria-labelledby="headingTwo"
              data-parent="#sidenavAccordion"
            >
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/review/create">
                  Create Review
                </Link>
                <Link className="nav-link sub_nav_link" to="/review/list">
                 List Review
                </Link>
                <Link className="nav-link sub_nav_link" to="/testimonial/all">
                 List Testimonials
                </Link>
              </nav>
            </div>

            <Link
              className="nav-link collapsed"
              data-toggle="collapse"
              data-target="#collapseQuotationList"
              aria-expanded="false"
              aria-controls="collapseQuotationList"
            >
              <div className="sb-nav-link-icon">
                <i className="fa fa-credit-card" />
              </div>
              Revenue's
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </Link>
            <div
              className="collapse"
              id="collapseQuotationList"
              aria-labelledby="headingTwo"
              data-parent="#sidenavAccordion"
            >
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/revenue/list">
                  All Revenues
                </Link>
             
              </nav>
            </div>
            
          </div>
        </div>
      </nav>
    </div>
  );
};

export default SideNavBar;
