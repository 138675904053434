import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";
import swal from "sweetalert";
import apiClient from "../../api/client";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

const AllFeaturedHotel = () => {
  const [isloaded, setIsLoaded] = useState(false);
  const [del, setDel] = useState(false);
  const [getList, setGetList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchorderagain();
  }, [del]);

  const fetchorderagain = async () => {
    setIsLoaded(true);
    const { data } = await apiClient.get("/featured/featured-hotel-all");

    setGetList(data);
    setIsLoaded(false);
  };

  const handlDeleteById = async (id) => {
    const a = sessionStorage.getItem("token");
    const config = {
        headers: {
          "x-auth-token": a,
        },
      };
    const result = await apiClient.delete("/featured/featured-hotel-delete", { id }, config);
    if (result.ok) {
      swal("Delete Success");
      setDel(!del);
    }
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Sizes</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => navigate("/")}>
                <i className="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="index.html">Dashboard</a>
            </li>
            <li className="breadcrumb-item active">Hotels</li>
            <li className="breadcrumb-item active">Featured Hotels</li>
          </ol>
          <div className="col-lg-8 col-md-12">
            <div className="card card-static-2 mt-30 mb-30">
              <div className="card-title-2">
                <h4>All Hotels *(Total Hotels {getList.length} )</h4>
                <Button
                  style={{ marginLeft: "220px" }}
                  variant="contained"
                  color="success"
                  onClick={(e) => navigate("/hotel/add-featured")}
                >
                  <AddIcon /> Add Hotels to featured
                </Button>
              </div>

              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <thead>
                      <tr>
                        <th>Id</th>

                        <th>Name</th>
                        <th>Image</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isloaded !== true && getList?.length > 0
                        ? getList?.map((row, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>

                                <td>{row.hotel.name}</td>
                                <td>
                                  <div className="cate-img-5">
                                    <img src={row?.hotel.image ? row?.hotel.image[0] : ""}
                                      alt="service-name"/>
                                  </div>
                                </td>
                                <td className="action-btns">
                                  {/* <Link to="/product/update" state={row}>
                                  <Typography className="edit-btn">
                                    <i className="fas fa-edit" />
                                  </Typography>
                                </Link> */}
                                  <Typography
                                    className="delete-btn"
                                    onClick={(e) => handlDeleteById(row._id)}
                                  >
                                    <Button
                                      size="small"
                                      variant="outlined"
                                      startIcon={<DeleteIcon />}
                                    >
                                      Delete
                                    </Button>
                                  </Typography>
                                </td>
                              </tr>
                            );
                          })
                        : "Loading..."}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AllFeaturedHotel;
