import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Button, Typography } from "@material-ui/core";
import apiClient from "./../../api/client";
import Pagination from "@mui/material/Pagination";


const AllNiyabat = () => {
  const [isloaded, setIsLoaded] = useState(false);
  const [getList, setGetList] = useState([]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState();

  useEffect(() => {
    fetchEnquiries();
  }, []);

  const fetchEnquiries = async () => {

   
    setIsLoaded(true);
    const { data } = await apiClient.get("/niyabat/get",{ pageNumber: currentPage,});
    console.log(data?.niyabats)
    setPageSize(data?.pageCount);
    setGetList(data?.niyabats);
    setIsLoaded(false);
  };


  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Customers</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => navigate("/")}>
                <i className="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="index.html">Dashboard</a>
            </li>
            <li className="breadcrumb-item active">Enquiries</li>
          </ol>
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mt-30 mb-30">
              <div className="card-title-2">
                <h4>All Enquiries</h4>
              </div>
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <thead>
                      <tr  >
                     
                                <th>Index</th>

                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Zayar Name</th>
                        <th>Father's Name</th>
                        <th style={{ width: 400 }}>Message</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isloaded !== true
                        ? getList?.map((row, index) => (
                            <tr key={index}>
                              {/* <td>{row._id}</td> */}
                              <td>{index + 1}</td>
                              <td>{row.name}</td>
                              <td>{row.email}</td>

                              <td>{row.mobile}</td>
                              <td>{row.zayarName}</td>
                              <td>{row.fatherName}</td>
                              <td>{row.message}</td>
                           
                              {/* <td><Link to="/enquiry/detail" state={row._id}>
                                      <Typography className="edit-btn">
                                        <i className="fas fa-edit" />
                                      </Typography>
                                    </Link></td> */}
                            </tr>
                          ))
                        : "Loading..."}
                    </tbody>
                  </table>
                </div>
                <Pagination
            count={pageSize}
            page={currentPage}
            siblingCount={1}
            onChange={(e, value) => setCurrentPage(value)}
          />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AllNiyabat;
