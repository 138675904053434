import React, { useState } from "react";
import { Button } from "@material-ui/core";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import apiClient from "../../api/client";

const CreateReview = () => {
  const [name, setName] = useState();
  const [message, setMessage] = useState();
  const [videoLink, setVideoLink] = useState();
  const [title, setTitle] = useState();

  const handleSubmit = async (event) => {
   
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "You want to Add New Review",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {  
        const result = await apiClient.post("/review/create", {
          name, videoLink, title, message
        });
        if (result.ok) {
          setMessage("")
          setName("")
          setVideoLink("")
          setTitle("")
          swal("Sucess");
          navigate("/review/list");
        } else {
          swal("Error Retry!");
        }
      }
    });
  };

  const navigate = useNavigate();



  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Review</h2>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>
            <li className="breadcrumb-item">
              <a href="//review/list">Review</a>
            </li>
            
          </ol>

          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="card card-static-2 mb-30">
                <div className="card-title-2">
                  <h4>Add New Review</h4>
                </div>
                <div className="card-body-table">
                  <div className="news-content-right pd-20">
                    <div className="col-lg-4 col-md-2">
                      <div className="form-group">
                        <label className="form-label">Title*</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Title"
                          name="title"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-2 ">
                      <div className="form-group">
                        <label className="form-label">
                          Message*
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Message for review"
                          name="message"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        />
                      </div>
                    </div>
                    {/* <br /> */}
                    <div className="col-lg-3 col-md-2 ">
                      <div className="form-group">
                        <label className="form-label">
                         Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name of reviewer"
                          name="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-2 ">
                      <div className="form-group">
                        <label className="form-label">
                         Video Link
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Video Link"
                          name="videoLink"
                          value={videoLink}
                          onChange={(e) => setVideoLink(e.target.value)}
                        />
                      </div>
                    </div>

                    <button
                      className="save-btn hover-btn"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Add New
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CreateReview;
