import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import swal from "sweetalert";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import apiClient from "./../../api/client";

const AddSpecialCategory = () => {
  const [id, setId] = useState();
  const [name, setName] = useState();
  const [cat, setCat] = useState();
  const [subcat, setsubCat] = useState();
  const [catId, setCatId] = useState("1");
  const [subcatId, setsubCatId] = useState("1");
  const [photo, setPhoto] = useState();
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  useEffect(() => {
    fetchCategories();
    fetchsubCategories();
  }, []);

  const fetchCategories = async () => {
    const { data } = await apiClient.get("/variation/get-categories");
    setCat(data);
  };
  const fetchsubCategories = async () => {
    const { data } = await apiClient.get("/variation/get-subcategories");
    setsubCat(data);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "You want to Add New Special Category",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        const formData = new FormData();
      formData.append("image", photo);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await apiClient.post(
        "/uploadSingleImage/uploadSingleImage",
        formData,
        config
      );
       createCategory(data);
      }
    });
  };

  const createCategory = async (item) => {
    const a = sessionStorage.getItem('token')
  
    const config = {
          headers: {
            "x-auth-token": a
          },
        };
    
    const result = await apiClient.post("/variation/specialcategory", {
      id,
      name,
      image: item,
      category: catId,
      subcategory: subcatId
   
      
    }, config);
    if (result.ok) {
      setId("");
      setName("");  
      setPhoto("");
      swal("Sucess");
      navigate("/specialcategory/all");
    } else {
      swal("Error Retry!");
    }
  };

  const navigate = useNavigate();

  const uploadFileHandler = async (e) => {
    setPhoto(e.target.files[0]);
  };

  const options = cat?.map((guest, index) => {
    return {
      label: guest.name,
      value: guest._id,
      key: index,
    };
  });
  const options2 = subcat?.map((guest, index) => {
    return {
      label: guest.name,
      value: guest._id,
      key: index,
    };
  });
  const handleChange = async (selected) => {
 setCatId(selected.value)
  }
  const handleChange2 = async (selected) => {
 setsubCatId(selected.value)
  }
  // const uploadFileHandlerBanner = async (e) => {
  //   setBanner(e.target.files[0]);
  // };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Special Category</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button
                variant="contained"
                onClick={(e) => navigate("/specialcategory/all")}
              >
                <i class="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/admin/product/create">Special Category</a>
            </li>
            <li className="breadcrumb-item active">Add Special Category</li>
          </ol>

          {/* Add category  */}
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="card card-static-2 mb-30">
                <div className="card-title-2">
                  <h4>Add New Special Category</h4>
                </div>
                <div className="card-body-table">
                  <div className="news-content-right pd-20">
                    <div className="col-lg-3 col-md-2">
                      <div className="form-group">
                        <label className="form-label">Special Category Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Special Category Name"
                          name="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                  
                    <div className="col-lg-3 col-md-2">
                      <div className="form-group">
                        <label className="form-label">Special Category Id*</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Id"
                          name="id"
                          value={id}
                          onChange={(e) => setId(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Parent-Category</label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            // defaultValue={}
                            isDisabled={isDisabled}
                            isLoading={isLoading}
                            isClearable={isClearable}
                            isRtl={isRtl}
                            isSearchable={isSearchable}
                            name="Category"
                            options={options}
                            onChange={handleChange}
                           
                          />
                         
                        </div>
                      </div>
                    <div className="col-lg-4 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Parent-Sub-Category</label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            // defaultValue={}
                            isDisabled={isDisabled}
                            isLoading={isLoading}
                            isClearable={isClearable}
                            isRtl={isRtl}
                            isSearchable={isSearchable}
                            name="Category"
                            options={options2}
                            onChange={handleChange2}
                            // getOptionLabel={(option) => option.name}
                            // getOptionValue={(option) => option._id}
                          />
                         
                        </div>
                      </div>
                    <br />
                    <div className="col-lg-3 col-md-2">
                      <div className="form-group">
                        <label className="form-label">Select Image </label>
                        <form>
                          <input type="file" onChange={uploadFileHandler} />
                        </form>
                      </div>
                    </div>
                  
                    
                  

                    <button
                      className="save-btn hover-btn"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Add New
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AddSpecialCategory;
