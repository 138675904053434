import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import SideNavBar from "./components/SideNavBar";

import Login from "./screen/Auth/Login";
import useAuth from "./auth/useAuth";
import Register from "./screen/Auth/Register";

import Dashboard from "./components/Dashboard";

import AllPackages from "./screen/Package/AllPackages";
import AllBookings from "./screen/Booking/AllBookings";

import ListUser from "./screen/User/ListUser";

import SendNotification from "./screen/User/SendNotification";
import Revenue from "./components/Revenue";

import BookingDetails from "./screen/Booking/BookingDetails";
import UpdateBooking from "./screen/Booking/UpdateBooking";
import CreateBanner from "./screen/Banner/CreateBanner";
import ListBanners from "./screen/Banner/ListBanners";
import AddPackage from "./screen/Package/AddPackage";
import UpdatePackage from "./screen/Package/UpdatePackage";
import AddHotel from "./screen/Hotel/AddHotel";
import AllHotel from "./screen/Hotel/AllHotel";
import AddBlog from "./screen/Blog/AddBlog";
import AddCategory from "./screen/Variations/AddCategory";
import AllCategories from "./screen/Variations/AllCategories";
import AllBlog from "./screen/Blog/AllBlog";
import UpdateBlog from "./screen/Blog/UpdateBlog";
import AddHotelCategory from "./screen/Hotel/AddHotelCategory";
import AllHotelCategory from "./screen/Hotel/AllHotelCategory";
import UpdateHotel from "./screen/Hotel/UpdateHotel";
import AllEnquiry from "./screen/Enquiry/AllEnquiry";
import AddFeatured from "./screen/Package/AddFeatured";
import AllFeatured from "./screen/Package/AllFeatured";
import AddFeaturedHotel from "./screen/Hotel/AddFeaturedHotel";
import AllFeaturedHotel from "./screen/Hotel/AllFeaturedHotel";
import Enquiry from "./screen/Enquiry/Enquiry";
import CreateReview from "./screen/Review/addReview";
import ListReviews from "./screen/Review/allReviews";
import AllNiyabat from "./screen/Niyabat/allNiyabat";
import AllSubCategories from "./screen/Variations/AllSubCategory";
import AllSpecialCategories from "./screen/Variations/AllSpecialCategory";
import AddSubCategory from "./screen/Variations/AddSubCategory";
import AddSpecialCategory from "./screen/Variations/AddSpecialCategory";
import AllTestimonials from "./screen/Review/AllTestimonials";
import EditCategory from "./screen/Variations/EditCategory";
import EditSubCategory from "./screen/Variations/EditSubCategory";
import EditSpecialCategory from "./screen/Variations/EditSpecialCategory";
import UpdateHotelCategory from "./screen/Hotel/UpdateHotelCategory";

const HeaderComponent = () => {
  const { user } = useAuth();
  if (!user) {
    return <Login />;
  }
  return (
    <div>
      <Header />
      <div id="layoutSidenav">
        <SideNavBar />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="/register" element={<Register />} />
          <Route path="/category/create" element={<AddCategory />} />
          <Route path="/category/edit" element={<EditCategory />} />
          <Route path="/subcategory/edit" element={<EditSubCategory />} />
          <Route
            path="/specialcategory/edit"
            element={<EditSpecialCategory />}
          />
          <Route path="/subcategory/create" element={<AddSubCategory />} />
          <Route
            path="/specialcategory/create"
            element={<AddSpecialCategory />}
          />
          <Route path="/hotel-category/create" element={<AddHotelCategory />} />
          <Route
            path="/hotel-category/update"
            element={<UpdateHotelCategory />}
          />
          <Route path="/banner/create" element={<CreateBanner />} />
          <Route path="/testimonial/all" element={<AllTestimonials />} />
          <Route path="/banner/list" element={<ListBanners />} />
          <Route path="/category/all" element={<AllCategories />} />
          <Route path="/subcategory/all" element={<AllSubCategories />} />
          <Route
            path="/specialcategory/all"
            element={<AllSpecialCategories />}
          />
          <Route path="/hotel-category/all" element={<AllHotelCategory />} />
          <Route path="/hotel/all" element={<AllHotel />} />
          <Route path="/hotel/create" element={<AddHotel />} />
          <Route path="/hotel/update" element={<UpdateHotel />} />
          <Route path="/review/create" element={<CreateReview />} />
          <Route path="/review/list" element={<ListReviews />} />
          <Route path="/niyabat/all" element={<AllNiyabat />} />
          <Route path="/package/all" element={<AllPackages />} />
          <Route path="/package/create" element={<AddPackage />} />
          <Route path="/package/update" element={<UpdatePackage />} />
          <Route path="/package/add-featured" element={<AddFeatured />} />
          <Route path="/package/featured" element={<AllFeatured />} />
          <Route path="/hotel/add-featured" element={<AddFeaturedHotel />} />
          <Route path="/hotel/featured" element={<AllFeaturedHotel />} />
          <Route path="/booking/list" element={<AllBookings />} />
          <Route path="/booking/detail" element={<BookingDetails />} />
          <Route path="/booking/update" element={<UpdateBooking />} />
          <Route path="/users/list" element={<ListUser />} />
          <Route path="/enquiry/all" element={<AllEnquiry />} />
          <Route path="/enquiry/detail" element={<Enquiry />} />
          <Route path="/user/notification" element={<SendNotification />} />

          <Route path="/revenue/list" element={<Revenue />} />

          <Route path="/blog/create" element={<AddBlog />} />
          <Route path="/blog/all" element={<AllBlog />} />
          <Route path="/blog/update" element={<UpdateBlog />} />
        </Routes>
      </div>
    </div>
  );
};

export default HeaderComponent;
