import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@material-ui/core";

import apiClient from "../../api/client";

const Enquiry = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const enquiryId = location.state;
  const [getList, setGetList] = useState();
useEffect(() => {
    fetchEnquiries();
  }, []);

  const fetchEnquiries = async () => {
    const a = sessionStorage.getItem("token");
    const config = {
        headers: {
            "x-auth-token": a,
        },
    };
   
   
    const { data } = await apiClient.get("/enquiry/getbyid",{productId: enquiryId}, config);
    console.log(data)
    setGetList(data);
  };
//   const date = new Date(order?.orderItems[0].bookingDateTime).toDateString();
//   const time = new Date(order?.orderItems[0].bookingDateTime).toLocaleTimeString([], {
//     hour: "2-digit",
//     minute: "2-digit",
//   });
 



  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="order">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Bookings</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button
                variant="contained"
                onClick={(e) => navigate("/enquiry/all")}
              >
                <i className="fas fa-arorder-left" /> Back
              </Button>
              {/* <Button variant="contained" onClick={createAndDownloadPdf}>
                <i className="fas fa-arorder-left" /> Download
              </Button> */}
            </div>
          </div>

          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="index.html">Dashboard</a>
            </li>
            <li className="breadcrumb-item ">Enquiry</li>
            <li className="breadcrumb-item active">Detail</li>
          </ol>
          <div className="pdf-print">
            <div className="col-lg-12 col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="card card-static-2 col-lg-5">
                  <div className="card-title-2">
                    <h4>Customer</h4>
                  </div>
                  <div style={{ padding: "5px" }}>
                    <p>Name: {getList?.name}</p>
                    <p>Email: {getList?.email}</p>
                    <p>Mobile: {getList?.mobile}</p>
                  
                  </div>
                </div>
                <div className="card card-static-2 col-lg-5">
                  <div className="card-title-2">
                    <h4>Enquiry Info</h4>
                  </div>
                  <div style={{ padding: "20px" }}>
                    <p>
                     Enquired for - {getList?.category}
                    </p>
                    <p style={{fontWeight: 500 }}>{getList?.hotel?.name ? getList?.hotel?.name : getList?.package?.name}</p>
                
                    <p>
                      Message:{" "} {getList?.message}
                      
                    </p>
                  </div>
                </div>
               
              </div>

            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Enquiry;
