import React, { useState } from "react";
import { Button } from "@material-ui/core";
import swal from "sweetalert";

import { useNavigate } from "react-router-dom";
import apiClient from "./../../api/client";

const AddCategory = () => {
  const [id, setId] = useState();
  const [name, setName] = useState();
  const [photo, setPhoto] = useState();
 

  const handleSubmit = async (event) => {
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "You want to Add New Category",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        const formData = new FormData();

      formData.append("image", photo);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await apiClient.post(
        "/uploadSingleImage/uploadSingleImage",
        formData,
        config
      );
      
        createCategory(data);

       
      }
    });
  };

  const createCategory = async (item) => {
    const a = sessionStorage.getItem('token')
  
    const config = {
          headers: {
            "x-auth-token": a
          },
        };
    
    const result = await apiClient.post("/variation/category", {
      id,
      name,
      image: item,
   
      
    }, config);
    if (result.ok) {
      setId("");
      setName("");
   
  
     
      setPhoto("");
      swal("Sucess");
      navigate("/category/all");
    } else {
      swal("Error Retry!");
    }
  };

  const navigate = useNavigate();

  const uploadFileHandler = async (e) => {
    setPhoto(e.target.files[0]);
  };
  // const uploadFileHandlerBanner = async (e) => {
  //   setBanner(e.target.files[0]);
  // };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Category</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button
                variant="contained"
                onClick={(e) => navigate("/category/list")}
              >
                <i class="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/admin/product/create">Category</a>
            </li>
            <li className="breadcrumb-item active">Add Category</li>
          </ol>

          {/* Add category  */}
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="card card-static-2 mb-30">
                <div className="card-title-2">
                  <h4>Add New Category</h4>
                </div>
                <div className="card-body-table">
                  <div className="news-content-right pd-20">
                    <div className="col-lg-3 col-md-2">
                      <div className="form-group">
                        <label className="form-label">Category Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Category Name"
                          name="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                  
                    <div className="col-lg-3 col-md-2">
                      <div className="form-group">
                        <label className="form-label">Category Id*</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Id"
                          name="id"
                          value={id}
                          onChange={(e) => setId(e.target.value)}
                        />
                      </div>
                    </div>
                    <br />
                    <div className="col-lg-3 col-md-2">
                      <div className="form-group">
                        <label className="form-label">Select Image </label>
                        <form>
                          <input type="file" onChange={uploadFileHandler} />
                        </form>
                      </div>
                    </div>
                  
                    
                  

                    <button
                      className="save-btn hover-btn"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Add New
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AddCategory;
