import React, { useEffect, useMemo, useRef, useState } from "react";
import useAuth from "../../auth/useAuth";
import apiClient from "./../../api/client";
import { Button } from "@material-ui/core";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { QuillFormats } from "./quill";
import ImageResize from "quill-image-resize-module-react";
Quill.register("modules/imageResize", ImageResize);

const UpdateBlog = () => {
  const quillRef = useRef();
  const location = useLocation();
  const data = location.state;
  const blogId = data._id;
  const [content, setContent] = useState(data?.content);
  const [subcatId, setsubCatId] = useState(data?.subcategories);
  const [specialcatId, setspecialCatId] = useState(data?.specialcategories);
  const [name, setName] = useState(data?.heading);
  const [description, setDescription] = useState(data?.content);
  const [photo, setPhoto] = useState();
  const [photo2, setPhoto2] = useState(data.image);
  const [checked, setChecked] = useState([]); // categories // categories
  const [checkedTag, setCheckedTag] = useState([]); // tags
  const [uploadVisible, setUploadVisible] = useState(false);
  const [categories, setCategories] = useState();
  const [tags, setTags] = useState();
  const [mtitle, setMtitle] = useState(data?.mtitle);
  const [mdesc, setMdesc] = useState(data?.mdesc);
  const [author, setAuthor] = useState(data?.user);
  const [subcategories, setsubCategories] = useState();
  const [specialcategories, setspecialCategories] = useState();

  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);

  const { user } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    getCategories();
    getsubCategories();
    getspecialCategories();
    getTags();
  }, []);

  const getCategories = async () => {
    const { data } = await apiClient.get("/variation/get-categories");
    setCategories(data);
  };
  const getsubCategories = async () => {
    const { data } = await apiClient.get("/variation/get-subcategories");
    setsubCategories(data);
  };
  const getspecialCategories = async () => {
    const { data } = await apiClient.get("/variation/get-specialcategories");

    setspecialCategories(data);
  };
  const getTags = async () => {
    const { data } = await apiClient.get("/variation/get-tags");
    setTags(data);
  };

  const uploadFileHandler = async (e) => {
    setPhoto(e.target.files[0]);
  };
  const onSubmit = async () => {
    if (photo) {
      const formdata = new FormData();
      formdata.append("image", photo);

      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const { data } = await apiClient.post(
          "/uploadSingleImage/uploadSingleImage",
          formdata,
          config
        );

        const result = await apiClient.post("/blog/update-blog", {
          blogId,
          heading: name,
          mtitle,
          mdesc,
          content,
          user: author,
          categories: checked,
          tags: checkedTag,
          image: data,
        });
        if (!result.ok) {
          setUploadVisible(false);
          swal("Blog not created");
        } else {
          setUploadVisible(false);
          swal("Blog Created Successfully");
          navigate("/blog/all");
        }
      } catch (error) {
        console.log(error);
        setUploadVisible(false);
      }
    } else {
      setUploadVisible(true);

      const result = await apiClient.post("/blog/update-blog", {
        blogId,
        heading: name,
        mtitle,
        mdesc,
        content,
        user: author,
        categories: checked,
        tags: checkedTag,
        image: photo2,
      });
      if (!result.ok) {
        setUploadVisible(false);
        swal("Blog not created");
      } else {
        setUploadVisible(false);
        swal("Blog Created Successfully");
        navigate("/blog/all");
      }
    }
  };

  const handleToggle = (c) => () => {
    // return the first index or -1
    const clickedCategory = checked.indexOf(c);
    const all = [...checked];

    if (clickedCategory === -1) {
      all.push(c);
    } else {
      all.splice(clickedCategory, 1);
    }

    setChecked(all);
  };

  const findOutCategory = (c) => {
    const result = checked.indexOf(c);
    if (result !== -1) {
      return true;
    } else {
      return false;
    }
  };

  const showCategories = () => {
    return (
      categories &&
      categories.map((c, i) => {
        return (
          <li key={i} className="list-unstyled form-check">
            <input
              checked={findOutCategory(c._id)}
              onChange={handleToggle(c._id)}
              type="checkbox"
              className="mr-2 form-check-input"
            />
            <label className="form-check-label">{c.name}</label>
          </li>
        );
      })
    );
  };

  const options = subcategories?.map((guest, index) => {
    return {
      label: guest.name,
      value: guest._id,
      key: index,
    };
  });
  const options2 = specialcategories?.map((guest, index) => {
    return {
      label: guest.name,
      value: guest._id,
      key: index,
    };
  });

  const handleChange = async (selected) => {
    setsubCatId(selected.value);
  };
  const handleChange2 = async (selected) => {
    setspecialCatId(selected.value);
  };

  const imageHandler = (e) => {
    const editor = quillRef.current.getEditor();

    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (/^image\//.test(file.type)) {
        const formData = new FormData();
        formData.append("image", file);
        // const res = await ImageUpload(formData); // upload data into server or aws or cloudinary
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const { data } = await apiClient.post(
          "/uploadSingleImage/uploadSingleImage",
          formData,
          config
        );

        // const url = res?.data?.url;
        console.log(data);
        editor.insertEmbed(editor.getSelection().index, "image", data);
      } else {
        // ErrorToast('You could only upload images.');
      }
    };
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["image", "link"],
          [
            {
              color: [
                "#000000",
                "#e60000",
                "#ff9900",
                "#ffff00",
                "#008a00",
                "#0066cc",
                "#9933ff",
                "#ffffff",
                "#facccc",
                "#ffebcc",
                "#ffffcc",
                "#cce8cc",
                "#cce0f5",
                "#ebd6ff",
                "#bbbbbb",
                "#f06666",
                "#ffc266",
                "#ffff66",
                "#66b966",
                "#66a3e0",
                "#c285ff",
                "#888888",
                "#a10000",
                "#b26b00",
                "#b2b200",
                "#006100",
                "#0047b2",
                "#6b24b2",
                "#444444",
                "#5c0000",
                "#663d00",
                "#666600",
                "#003700",
                "#002966",
                "#3d1466",
              ],
            },
          ],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      imageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize"],
      },
    }),
    []
  );

  return (
    <div id="layoutSidenav_content">
      {uploadVisible ? (
        <div
          style={{ textAlign: "center", marginTop: "250px", fontSize: "25px" }}
        >
          Loading... Please wait!
        </div>
      ) : (
        <main>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-5 col-md-9 col-lg-6">
                <h2 className="mt-30 page-title">Blogs</h2>
              </div>
              <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
                <Button variant="contained" onClick={(e) => navigate("/")}>
                  <i className="fas fa-arrow-left" /> Back
                </Button>
              </div>
            </div>
            <ol className="breadcrumb mb-30">
              <li className="breadcrumb-item">
                <a href="/">Dashboard</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/admin/product/create">Blogs</a>
              </li>
              <li className="breadcrumb-item active">Update Blog</li>
            </ol>

            {/* Add category  */}
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="card card-static-2 mb-30">
                  <div className="card-title-2">
                    <h4>update Blog</h4>
                  </div>
                  <div className="card-body-table">
                    <div className="news-content-right pd-20">
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Heading</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=" Heading"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Author</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Author"
                            name="author"
                            value={author}
                            onChange={(e) => setAuthor(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Meta Title</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Meta Title"
                            name="mtitle"
                            value={mtitle}
                            onChange={(e) => setMtitle(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Meta Description</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Meta Description"
                            name="mdesc"
                            value={mdesc}
                            onChange={(e) => setMdesc(e.target.value)}
                          />
                        </div>
                      </div>

                      {/* <div className="col-lg-4 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Category</label>
                          <Select
                            className="form-control"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={catId}
                            label="Category"
                            onChange={(e) => setCatId(e.target.value)}
                          >
                            {categories?.map((item, index) => {
                              return (
                                <MenuItem value={item._id}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                      </div> */}
                      <br />
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Select Image </label>
                          <form>
                            <input type="file" onChange={uploadFileHandler} />
                          </form>
                        </div>
                      </div>
                      <br />
                      <div className="col-lg-12 col-md-3 ">
                        <div className="form-group">
                          <label className="form-label">Content</label>
                          <ReactQuill
                            theme="snow"
                            formats={QuillFormats}
                            ref={quillRef}
                            value={content}
                            modules={modules}
                            onChange={setContent}
                            placeholder="Write description..."
                          />
                        </div>
                      </div>
                      <div>
                        <h5>Categories</h5>
                        <hr />

                        <ul style={{ maxHeight: "200px", overflowY: "scroll" }}>
                          {showCategories()}
                        </ul>
                      </div>
                      {/* <div>
                        <h5>Tags</h5>
                        <hr />
                        <ul style={{ maxHeight: '200px', overflowY: 'scroll' }}>{showTags()}</ul>
                    </div> */}
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Sub-Category</label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            // defaultValue={}
                            isDisabled={isDisabled}
                            isLoading={isLoading}
                            isClearable={isClearable}
                            isRtl={isRtl}
                            isSearchable={isSearchable}
                            name="Category"
                            options={options}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Special-Category</label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            // defaultValue={}
                            isDisabled={isDisabled}
                            isLoading={isLoading}
                            isClearable={isClearable}
                            isRtl={isRtl}
                            isSearchable={isSearchable}
                            name="Category"
                            options={options2}
                            onChange={handleChange2}
                          />
                        </div>
                      </div>
                      <button
                        className="save-btn hover-btn"
                        type="submit"
                        onClick={onSubmit}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
    </div>
  );
};

export default UpdateBlog;
