import React, { useState, useEffect } from "react";
import {
  Button,
  makeStyles,
  Table,
  Select,
  MenuItem,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputBase,
  IconButton,
} from "@material-ui/core";
import swal from "sweetalert";
import apiClient from "../../api/client";
import { useNavigate } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import "./AddService.css";

const intialState = {
  jobs: [],
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  table: {
    minWidth: 650,
  },

  headerContainer: {
    // display: 'flex'
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(1),
  },
}));

const AddHotel = () => {
  const [file2, setFile2] = useState([]);
  const [cat, setCat] = useState();
  const [catId, setCatId] = useState();
  const [jobsData, setJobsData] = useState(intialState);

  const navigate = useNavigate();
  const classes = useStyles();

  const [state, setState] = useState({
    name: "",
    image: "",
    nearest: "",
    category: "",
    description: "",
    details: [],
    price: "",
    discount: "",
    rating: "",
    notes: "",
  });
  useEffect(() => {
    fetchCategories();
  }, []);
  const uploadFileHandler = async (e) => {
    setFile2([...file2, ...e.target.files]);
  };

  function handleChange(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }
  const fetchCategories = async () => {
    const { data } = await apiClient.get("/variation/get-hotel-categories");
    setCat(data);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (file2.length < 1) {
      swal("Please select image");
    } else {
      swal({
        title: "Are you sure?",
        text: "You want to Add New Hotel",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (success) => {
        if (success) {
          if (file2) {
            const formData = new FormData();

            for (const file of file2) {
              formData.append("image", file, file.name);
            }

            const config = {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            };
            const { data } = await apiClient.post(
              "/upload/uploadImages",
              formData,
              config
            );

            if (data) {
              const result1 = await apiClient.post("/hotel/create", {
                name: state.name,
                image: data,
                price: state.price,
                discount: state.discount,
                category: catId,
                notes: state.notes,
                description: state.description,
                nearest: state.nearest,
                details: jobsData.jobs,
                hotelRating: state.rating,
              });

              if (result1.ok) {
                setState({
                  name: "",
                  image: "",
                  description: "",
                  details: [],
                  nearest: "",
                  price: "",
                  discount: "",
                  rating: "",
                  notes: "",
                });
                setFile2([]);
                swal("Success!! Hotel added");
                navigate("/hotel/all");
              } else {
                swal(
                  "Hotel Upload failed due to network issue or missing fields"
                );
              }
            } else {
              swal("Hotel upload Failed. Please Try again!!!!!");
              navigate("/hotel/all");
            }
          }
        }
      });
    }
  };

  const handleChange2 = (index, e) => {
    const values = [...jobsData.jobs];
    values[index] = e.target.value;
    setJobsData({ ...jobsData, jobs: values });
  };

  const handleAddField = (e) => {
    e.preventDefault();
    setJobsData((prevState) => ({
      ...prevState,
      jobs: [...prevState.jobs, ""],
    }));
  };

  const handleRemoveField = (index) => {
    const values = jobsData.jobs;
    values.splice(index, 1);
    setJobsData((prevState) => ({ ...prevState, values }));
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Hotels</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => this.handleBack()}>
                <i class="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/admin/product/create">Hotels</a>
            </li>
            <li className="breadcrumb-item active">Add Hotel</li>
          </ol>

          {/* Add prd  */}
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="card card-static-2 mb-30">
                <div className="card-title-2">
                  <h4>Add New Hotel</h4>
                </div>
                <div className="card-body-table">
                  <div className="news-content-right pd-20">
                    <div className="row">
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Hotel Name*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Name"
                            name="name"
                            value={state.name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-2">
                      <div className="form-group">
                        <label className="form-label">Hotel Stars*</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="1-5 Star Rating"
                          name="rating"
                          value={state.rating}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <br />

                    <br />
                    <div className="col-lg-8 col-md-2 mt-3">
                      <div className="form-group">
                        <label className="form-label">Description*</label>
                        <input
                          style={{ overflow: "scroll" }}
                          type="text"
                          className="form-control"
                          placeholder="Product Description"
                          name="description"
                          value={state.description}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-lg-8 col-md-2 mt-3">
                      <div className="form-group">
                        <div>
                          <TableContainer
                            component={Paper}
                            className="tb-container"
                          >
                            <Table
                              className={classes.table}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>Details</TableCell>
                                  <TableCell>Action</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {jobsData?.jobs?.map((itemField, index) => (
                                  <TableRow key={index}>
                                    <TableCell align="left">
                                      <InputBase
                                        sx={{ ml: 1, flex: 1 }}
                                        type="text"
                                        name="name"
                                        onChange={(e) =>
                                          handleChange2(index, e)
                                        }
                                        value={itemField}
                                        placeholder="Detail"
                                      />
                                    </TableCell>

                                    <TableCell align="left">
                                      <IconButton
                                        onClick={() => handleRemoveField(index)}
                                      >
                                        <DeleteOutlinedIcon
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                          }}
                                        />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <div className="addButton">
                            <button onClick={handleAddField}>+</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-2 col-md-2 mt-3">
                        <div className="form-group">
                          <label className="form-label">Category*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Category"
                            name="category"
                            value={state.category}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-2 col-md-2 mt-3">
                        <div className="form-group">
                          <label className="form-label">Price*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Hotel Price"
                            name="price"
                            value={state.price}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Product Category*
                          </label>
                          <Select
                            className="form-control"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={catId}
                            label="Category"
                            onChange={(e) => setCatId(e.target.value)}
                          >
                            {cat?.map((item, index) => {
                              return (
                                <MenuItem value={item._id}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 mt-3">
                        <div className="form-group">
                          <label className="form-label">Nearest place*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Nearest to"
                            name="nearest"
                            value={state.nearest}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-lg-2 col-md-2 mt-3">
                        <div className="form-group">
                          <label className="form-label">Discount*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Hotel Disount"
                            name="discount"
                            value={state.discount}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-2 mt-3">
                      <div className="form-group">
                        <label className="form-label">Notes*</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Notes"
                          name="notes"
                          value={state.notes}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body-table">
                  <div className="news-content-right pd-20">
                    <div className="col-lg-3 col-md-2">
                      <div className="form-group">
                        <label className="form-label">
                          Select images for the above Hotel
                        </label>
                        <form>
                          <input
                            type="file"
                            multiple
                            onChange={uploadFileHandler}
                          />
                        </form>
                      </div>
                    </div>

                    <button
                      className="save-btn hover-btn"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Add Hotel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AddHotel;
