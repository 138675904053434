import React from "react";
import useAuth from "../auth/useAuth";

const Header = () => {
  const { logOut } = useAuth();
  return (
    <div>
      <nav className="sb-topnav navbar navbar-expand navbar-light bg-clr">
        <a className="navbar-brand logo-brand" href="/">
          Khuddamezair.com Admin
        </a>

        <ul className="navbar-nav ml-auto mr-md-0">
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fas fa-user fa-fw" />
            </a>
            <div
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="userDropdown"
            >
              <span
                className="dropdown-item admin-dropdown-item"
                onClick={() => logOut()}
              >
                Logout
              </span>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Header;
