import React, { useState, useEffect } from "react";
import {
  Button,
  MenuItem,
  Select,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputBase,
  IconButton,
} from "@material-ui/core";
import swal from "sweetalert";
import apiClient from "../../api/client";
import { useLocation, useNavigate } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import "./AddService.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  table: {
    minWidth: 650,
  },

  headerContainer: {
    // display: 'flex'
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(1),
  },
}));

const UpdateHotel = () => {
  const loc = useLocation();
  const intialState = {
    jobs: loc?.state?.details,
  };

  const [file2, setFile2] = useState([]);
  const [cat, setCat] = useState();
  const [catId, setCatId] = useState(loc?.state?.category);
  const [jobsData, setJobsData] = useState(intialState);
  const [file3, setFile3] = useState([]);
  const navigate = useNavigate();
  const classes = useStyles();
  useEffect(() => {
    fetchCategories();
  }, []);
  const [state, setState] = useState({
    name: loc.state.name,
    image: loc.state.image,
    description: loc.state.description,
    hotel: loc.state.hotel,
    nearest: loc.state.nearest,
    details: [],
    category: loc?.state?.category,
    price: loc.state.price,
    discount: loc.state.discount,
    rating: loc.state.hotelRating,

    notes: "",
  });
  const [hotelImages, sethotelImages] = useState(loc?.state?.image);

  const fetchCategories = async () => {
    const { data } = await apiClient.get("/variation/get-hotel-categories");
    setCat(data);
  };

  function handleChange(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "You want to Update Service",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        const result1 = await apiClient.post("/hotel/update", {
          name: state.name,
          id: loc.state._id,
          image: hotelImages,
          hotel: state.hotel,
          nearest: state.nearest,
          price: state.price,
          discount: state.discount,
          category: catId,
          notes: state.notes,
          description: state.description,
          hotelRating: state.rating,
          details: jobsData?.jobs,
        });

        if (result1.ok) {
          setState({
            name: "",
            hotel: "",
            image: "",
            description: "",
            nearest: "",
            details: "",

            price: "",
            discount: "",

            notes: "",
          });
          setFile2([]);
          swal("Success Service added");
          navigate("/hotel/all");
        } else {
          swal("Service Upload failed due to network issue or missing fields");
        }
      }
    });
  };

  const handleChange2 = (index, e) => {
    const values = [...jobsData.jobs];
    values[index] = e.target.value;
    setJobsData({ ...jobsData, jobs: values });
  };

  const handleAddField = (e) => {
    e.preventDefault();
    setJobsData((prevState) => ({
      ...prevState,
      jobs: [...prevState.jobs, ""],
    }));
  };

  const handleRemoveField = (index) => {
    const values = jobsData.jobs;
    values.splice(index, 1);
    setJobsData((prevState) => ({ ...prevState, values }));
  };

  const removeImage = async (value) => {
    const result = await apiClient.delete("/variation/deleteimage", {
      image: value,
    });
    if (result.ok) {
      let newArr = hotelImages.filter((img) => img !== value);
      sethotelImages(newArr);
    }
  };

  const uploadFileHandler = async (e) => {
    setFile3([...file3, ...e.target.files]);
  };

  const handleSubmit2 = async () => {
    if (file3) {
      const formData = new FormData();

      for (const file of file3) {
        formData.append("image", file, file.name);
      }

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const result = await apiClient.post(
        "/upload/uploadImages",
        formData,
        config
      );
      if (result.ok) {
        swal("Images Uploaded Successfully");
        setFile3([]);
        sethotelImages([...hotelImages, ...result.data]);
      } else {
        swal("Error Retry!");
      }
    }
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Hotels</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => this.handleBack()}>
                <i class="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/admin/product/create">Hotels</a>
            </li>
            <li className="breadcrumb-item active">Update Hotel</li>
          </ol>

          {/* Add prd  */}
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="card card-static-2 mb-30">
                <div className="card-title-2">
                  <h4>Update Hotel</h4>
                </div>
                <div className="card-body-table">
                  <div className="news-content-right pd-20">
                    <div className="col-lg-3 col-md-2">
                      <div className="form-group">
                        <label className="form-label">Hotel Title*</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Product Name"
                          name="name"
                          value={state.name}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Hotel Stars*</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="1-5 Star Rating"
                          name="rating"
                          value={state.rating}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <br />

                    <div className="col-lg-8 col-md-2 mt-3">
                      <div className="form-group">
                        <label className="form-label">Description*</label>
                        <input
                          style={{ overflow: "scroll" }}
                          type="text"
                          className="form-control"
                          placeholder="Product Description"
                          name="description"
                          value={state.description}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-lg-8 col-md-2 mt-3">
                      <div className="form-group">
                        <div>
                          <TableContainer
                            component={Paper}
                            className="tb-container"
                          >
                            <Table
                              className={classes.table}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>Details</TableCell>
                                  <TableCell>Action</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {jobsData?.jobs?.map((itemField, index) => (
                                  <TableRow key={index}>
                                    <TableCell align="left">
                                      <InputBase
                                        sx={{ ml: 1, flex: 1 }}
                                        type="text"
                                        name="name"
                                        onChange={(e) =>
                                          handleChange2(index, e)
                                        }
                                        value={itemField}
                                        placeholder="Detail"
                                      />
                                    </TableCell>

                                    <TableCell align="left">
                                      <IconButton
                                        onClick={() => handleRemoveField(index)}
                                      >
                                        <DeleteOutlinedIcon
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                          }}
                                        />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <div className="addButton">
                            <button onClick={handleAddField}>+</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-2 col-md-2 mt-3">
                        <div className="form-group">
                          <label className="form-label">Price*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Paxkage S.P"
                            name="price"
                            value={state.price}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-lg-2 col-md-2 mt-3">
                        <div className="form-group">
                          <label className="form-label">Discount*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Disount"
                            name="discount"
                            value={state.discount}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-2 col-md-2 mt-3">
                        <div className="form-group">
                          <label className="form-label">Hotel*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Hotel"
                            name="hotel"
                            value={state.hotel}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Product Category*
                          </label>
                          <Select
                            className="form-control"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={catId}
                            label="Category"
                            onChange={(e) => setCatId(e.target.value)}
                          >
                            {cat?.map((item, index) => {
                              return (
                                <MenuItem value={item._id}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 mt-3">
                        <div className="form-group">
                          <label className="form-label">Nearest place*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Nearest to"
                            name="nearest"
                            value={state.nearest}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-2 mt-3">
                      <div className="form-group">
                        <label className="form-label">Notes*</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Notes"
                          name="notes"
                          value={state.notes}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <label className="form-label">Hotel Images*</label>
                    <div style={{ display: "flex" }}>
                      <form>
                        <input
                          type="file"
                          multiple
                          onChange={uploadFileHandler}
                        />
                      </form>
                      <button
                        className="save-btn hover-btn"
                        type="submit"
                        onClick={handleSubmit2}
                      >
                        Update Images
                      </button>
                    </div>

                    {hotelImages?.length > 0 && (
                      <p
                        style={{
                          fontWeight: "500",
                          textDecoration: "underline",
                        }}
                      >
                        Existing Images( {hotelImages?.length})
                      </p>
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                        gap: "10px",
                      }}
                    >
                      {hotelImages?.map((item, i) => {
                        return (
                          <div
                            className="prd-img"
                            onClick={() => removeImage(item)}
                          >
                            <img
                              key={i}
                              src={item}
                              alt="product-img"
                              class="update-img"
                            />
                            <div class="middle">
                              <div class="text" style={{ cursor: "pointer" }}>
                                Delete
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="card-body-table">
                  <div className="news-content-right pd-20">
                    <button
                      className="save-btn hover-btn"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Update Hotel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default UpdateHotel;
