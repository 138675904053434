import React, { useEffect, useState } from "react";
import apiClient from "../../api/client";
import { Button, Typography } from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Pagination from "@mui/material/Pagination";
import useAuth from "../../auth/useAuth";

const AllBlog = () => {
  const [blogs, setBlogs] = useState();
  const [isloaded, setIsLoaded] = useState(false);
  const [delmsg, setDelMsg] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState();

  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    fetchBlogs();
  }, [delmsg]);

  const fetchBlogs = async () => {
    setIsLoaded(true);
    const { data } = await apiClient.get("/blog/blogs", {  pageNumber: currentPage, });
    setPageSize(data?.pageCount);
    setBlogs(data?.blogs);
    setIsLoaded(false);
  };

  const handleDelete = async (item) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete this blog?",
      icon: "warning",
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        const blogId = item._id;
        const result = await apiClient.delete("/blog/delete-blogs", {
          blogId,
          user,
        });
        if (result.ok) {
          swal("Removed successfully");
        } else {
          swal("Cant remove error!");
        }
        setDelMsg(!delmsg);
      }
    });
  };
  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Blogs</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => navigate("/")}>
                <i className="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="index.html">Dashboard</a>
            </li>
            <li className="breadcrumb-item active">Blogs</li>
          </ol>
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mt-30 mb-30">
              <div className="card-title-2">
                <h4>All Blogs</h4>
              </div>
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <thead>
                      <tr>
                        <th style={{ width: 60 }}>No.</th>
                        <th style={{ width: 100 }}>Image</th>
                        <th>Heading</th>
                        <th>Author</th>

                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isloaded !== true && blogs?.length > 0
                        ? blogs?.map((row, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  <div className="cate-img-5">
                                    <img src={row.image} alt="product-name" />
                                  </div>
                                </td>
                                <td>{row?.heading}</td>

                                <td>{row?.user}</td>

                                <td className="action-btns">
                                  <Link to="/blog/update" state={row}>
                                    <Typography className="edit-btn">
                                      <i
                                        className="fas fa-edit"
                                        style={{ color: "blue" }}
                                      />
                                    </Typography>
                                  </Link>
                                </td>
                                <td>
                                  <Typography
                                    className="delete-btn"
                                    onClick={(e) => handleDelete(row)}
                                  >
                                    <i
                                      className="fas fa-trash-alt"
                                      style={{ color: "red" }}
                                    />
                                  </Typography>
                                </td>
                              </tr>
                            );
                          })
                        : "Loading..."}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <Pagination
            count={pageSize}
            page={currentPage}
            siblingCount={1}
            onChange={(e, value) => setCurrentPage(value)}
          />
        </div>
      </main>
    </div>
  );
};

export default AllBlog;
