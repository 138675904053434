import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@material-ui/core";
import { saveAs } from "file-saver";

import axios from "axios";

const BookingDetails = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const order = location.state;
 console.log(order)
  const date = new Date(order?.arrivalDate).toDateString();
  const time = new Date(order?.departureDate).toDateString();
  const orderItems = order;

//   const createAndDownloadPdf = async () => {
//     var totalSaved = 0;
//     for (let i = 0; i < orderItems.length; i++) {
//       totalSaved += parseInt(orderItems[i].discount);
//     }

//     await axios
//       .post(`http://localhost:5000/api/create-pdf`, {
//         name: order.user.name,
//         id: order._id,
//         address: order.shippingAddress,
//         deliverySlot: order.deliverySlot,
//         PaymentMode: order.paymentMethod,
//         deliveryCharge: order.deliveryCharge,
//         total: order.totalPrice,
//         items: order.orderItems,
//         totalSaved,
//       })
//       .then(() =>
//         axios.get(`http://localhost:5000/api/fetch-pdf`, {
//           responseType: "blob",
//         })
//       )
//       .then((res) => {
//         const pdfBlob = new Blob([res.data], { type: "application/pdf" });

//         saveAs(pdfBlob, "invoice.pdf");
//       });
//   };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="order">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Bookings</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button
                variant="contained"
                onClick={(e) => navigate("/booking/list")}
              >
                <i className="fas fa-arorder-left" /> Back
              </Button>
              {/* <Button variant="contained" onClick={createAndDownloadPdf}>
                <i className="fas fa-arorder-left" /> Download
              </Button> */}
            </div>
          </div>

          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="index.html">Dashboard</a>
            </li>
            <li className="breadcrumb-item ">Bookings</li>
            <li className="breadcrumb-item active">Detail</li>
          </ol>
          <div className="pdf-print">
            <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 col-lg-6">
                  <div className="card-title-2">
                    <h4>Customer Name: {""} {order?.user?.name}</h4>
                  </div>
                  <div style={{ padding: "5px" }}>
                   
                    
                  
                  </div>
                </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
               
                <div className="card card-static-2 col-lg-6">
                  <div className="card-title-2">
                    <h4>Order Info</h4>
                  </div>
                  <div style={{ padding: "20px" }}>
                    <p>
                     Arival - {date}
                    </p>
                    <p>
                     Departure - {time}
                    </p>
                    <p>Payment Method: {order?.paymentMethod}</p>
                    <p>Status: {order?.deliveryStatus}</p>
                    <p>
                      Payment Status:{" "}
                      {order?.deliveryStatus !== "Cancelled"
                          ? "COD"
                          : "Cancelled"
                       }
                    </p>
                  </div>
                </div>
                <div className="card card-static-2 col-lg-6">
                  <div className="card-title-2">
                    <h4>Booking Details</h4>
                  </div>
                  <div style={{ padding: "20px" }}>
                    <p>Package: {order?.package?.name}, </p>
                    <p>Person: {order?.persons}, </p>
                    <p>Price/Person: {order?.itemsPrice}, </p>
                    <p>TotalPrice: {order?.totalPrice}</p>
                  
                  </div>
                </div>
              </div>

              
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default BookingDetails;
